<template>
  <div data-testid="vonage-home-page-blog-section" class="bg-purple-900 color-canvas pt-14 pb-12">
    <SectionWithMaxWidth>
      <div class="flex flex-col xl:flex-row px-[24px] sm:px-0">
        <div class="flex flex-col px-[24px] medium:px-[24px] mobile-large:px-0 lg:px-0">
          <div class="text-heading-2 text-wrap md:w-full xl:w-[430px] sm:w-[260px] mobile-large:w-auto">
            {{ blok.heading }}
          </div>
          <div class="mt-6 inline-grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-4">
            <div
              v-for="feature of blok.features"
              :key="feature"
              data-test-id="home-page-developer-events-points"
              class="flex gap-2 items-center"
            >
              <VIcon name="check-circle-line" class="color-success-300 h-[22px] w-[22px] shrink-0" :size="-5" />
              <div data-test-id="home-page-developer-events-feature" class="text-base xl:text-xs">
                {{ feature }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow">
          <div
            data-test-id="home-page-developer-event-cards"
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 place-items-center md:place-items-stretch mt-14 xl:mt-0 mb-6 xl:mb-0"
          >
            <VonageHomePageEventCard v-for="event in latestEvents" :key="event.id" :event-details="event" />
          </div>
          <div class="flex flex-col md:flex-row mt-4">
            <div class="flex md:hidden mt-4 mb-9 justify-center sm:justify-start">
              <NuxtLink href="/community">
                <VButton
                  class="vvd-theme-alternate"
                  size="condensed"
                  label="View All Events"
                  icon="arrow-right-line"
                  icon-trailing
                />
              </NuxtLink>
            </div>
            <div class="grid grid-cols-3 sm:grid-cols-6 gap-[38px] mt-2 text-left md:text-center">
              <VonageHomePageEventSocialLinks
                v-for="socialLink in blok.socialLinks"
                :key="socialLink._uid"
                :link="socialLink"
              />
            </div>
            <div class="flex-grow" />
            <div class="hidden md:flex mt-2">
              <NuxtLink href="/community">
                <VButton
                  class="vvd-theme-alternate"
                  size="condensed"
                  label="View All Events"
                  icon="arrow-right-line"
                  icon-trailing
                />
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </SectionWithMaxWidth>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { VButton, VIcon } from '@vonage/vivid-vue';
import { getThreeLatestEventDetails } from '@/api';
import type { VonageHomePageEventsSection } from '@/types/storyblok';
import VonageHomePageEventCard from '@/components/home/vonage/events/VonageHomePageEventCard.vue';
import VonageHomePageEventSocialLinks from '@/components/home/vonage/events/VonageHomePageEventSocialLinks.vue';
import SectionWithMaxWidth from '@/components/utils/SectionWithMaxWidth.vue';

defineProps({
  blok: { type: Object as PropType<VonageHomePageEventsSection>, required: true },
});

const latestEvents: API.CommunityEvent[] = await getThreeLatestEventDetails();
</script>
